<template>
    <div>
        <page-header pageTitle="LOTS.READJUSTMENT_TITLE" :btnNew="allotments?.length > 0 ? 'ACTIONS.NEW' : ''"
                     @newType="selectModal('create')"></page-header>
        <div class="card" v-if="hastwoAllotments">
            <div class="card-header">
                <div class="card-title">
                    <label>{{ t('NAV.ALLOTMENT') }}</label>
                </div>
            </div>
            <div class="card-body pt-0">
                <Select2 :settings="{ width: '50%', placeholder: 'Selecione um Loteamento' }" id="allotment"
                         name="allotment"
                         :options="allotments"
                         v-model="allotmentId"
                         @select="indexReadjustment"/>
            </div>
        </div>

        <div v-if="this.items?.data && !this.items.data.validations" class="card mw-100">
            <div class="row mw-100">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4" v-if="!widthMd(992)">
                    <div class="font-weight-bolder mt-2 ml-4 text-left">
                        {{ this.t('READJUSTMENT.APPLY_BY') }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4">
                    <div class="font-weight-bolder mt-2 ml-4 text-left">
                        {{ widthMd(992) ? this.t('READJUSTMENT.TARGET_AB') : this.t('READJUSTMENT.TARGET') }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3" v-if="!widthMd(992)">
                    <div class="font-weight-bolder mt-2 text-left" style="margin-left: 2.4rem">
                        {{ this.t('READJUSTMENT.TYPE') }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-3 col-3">
                    <div class="font-weight-bolder mt-2 ml-2 text-left">
                        {{
                            widthMd(768) ? this.t('READJUSTMENT.VALUE_OR_PERCENTAGE_AB') : this.t('READJUSTMENT.VALUE_OR_PERCENTAGE')
                        }}
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                    <div class="font-weight-bolder mt-2 text-left" style="margin-left: 14%">
                        {{ widthMd(768) ? this.t('READJUSTMENT.CREATED_AT_AB') : this.t('READJUSTMENT.CREATED_AT') }}
                    </div>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 font-bold"
                     style="font-size: 1rem;">
                </div>
            </div>
            <div v-for="(iten, key) in items.data" :key="iten.id" :class="key !=0 ? 'border-top2': ''">
                <div class="row mw-100 space-between mt-2">
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4" v-if="!widthMd(992)">
                        <div class="list-primary hide-text text-left">
                            <div class="w-100">
                                {{ iten.user_name ? iten.user_name : ' ' }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4">
                        <div class="list-primary hide-text text-left"
                             :style="widthMd(992) ?'margin-left: -0.4rem;' :''">
                            <div class="w-auto">
                                {{ targetConversion(iten.target) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3" v-if="!widthMd(992)">
                        <div class="list-primary hide-text text-left">
                            {{ typeConversion(iten.type) }}
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                        <div class="list-primary hide-text text-left"
                             :style="widthMd(768)? 'margin-left: -1.8rem' : '' ">
                            <div class="w-auto">
                                {{
                                    iten.type === 1 ? ('% ' + iten.value.replace('.', ',')) : toCurrency(iten.value)
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                        <div class="list-primary hide-text text-left"
                             :style="widthMd(992) ? 'margin-left: -2rem;' : ''">
                            {{ dateFormat(iten.created_at) }}
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" style="margin-left: -0.1rem">
                        <div class="text-right">
                            <a type="button" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false" class="w-100"
                               style="font-size: 20px" id="listDropdown">
                                <i class="icon-more_vert" title="Opções"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-black">
                                <div class="dropdown-item pointer text-white"
                                     data-toggle="modal" data-target="#customModalTwo"
                                     @click="selectModal('view', iten.id)">
                                    <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-else>
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('LOTS.NONE_READJUSTMENT_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <modal :modal-xl="true" :titleModal="modalTitle">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <readjustment-lot-component v-if="modalswitch === '1'" :allotment-id-index="allotments[0]?.id"
                                        :allotment-index="allotments" :allotmentIdIndex="allotmentId"
                                        :properties-type="propertiesType"
                                        :properties-status="propertiesStatus"
                                        @Readjusted="indexReadjustment"></readjustment-lot-component>
            <show-readjustment-component v-if="modalswitch === '2' && readjust"
                                         :reajustIndex="readjust"></show-readjustment-component>
        </modal>
        <modal-select-allotment v-if="allotments" :showModal="showAllotmentSelect"
                                @showModal="showAllotmentSelect = $event"
                                @allotmentSelected="allotmentSelected">
        </modal-select-allotment>

        <div class="row gutters">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="items && items.data && items.data[0]" :items="items" :to="'/reajustes'"
                                      @changePage="indexReadjustment(filter, $event)"></pagination-component>
            </div>
            <div class="col-3" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Reajustes por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Selecione" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/layouts/pageHeader";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Modal from "@/components/modal";
import readjustmentLotComponent from "../lot/readjustmentLotComponent";
import {mapState} from 'vuex';
import Validate from '@/components/validate.vue';
import Select2 from 'vue3-select2-component';
import modalSelectAllotment from "../allotment/modalSelectAllotmentComponent.vue";
import Readjustment from "../../services/Readjustment";
import showReadjustmentComponent from "./showReadjustmentComponent";
import Allotments from "../../services/Allotments";
import PaginationComponent from "@/components/layouts/PaginationComponent";
import widthMd from '../../mixins/widthMd';
import propertyTypes from '../../services/propertyTypes';
import Properties from '../../services/Properties';

export default {
    name: "indexReadjustmentComponent",
    mixins: [Validate, widthMd],
    components: {
        PageHeader,
        Modal,
        Select2,
        readjustmentLotComponent,
        modalSelectAllotment,
        showReadjustmentComponent,
        PaginationComponent,
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
            hastwoAllotments: 'hastwoAllotments',
            allotmentLoad: 'allotmentLoad',
            paginate: 'paginate',
        }),
    },
    data() {
        return {
            perPage: null,
            propertiesStatus: [],
            propertiesType: [],
            modalTitle: '',
            modalswitch: null,
            allotmentId: null,
            showAllotmentSelect: null,
            items: null,
            readjust: null,
        }
    },
    async mounted() {
        await this.listPropertyesType();
        await this.listPropertiesStatus();
        this.allotmentId = localStorage.getItem('allotmentId');
        this.showAllotmentSelect = this.hastwoAllotments && (localStorage.getItem('allotmentId') ? false : true);
        this.indexReadjustment();
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    watch: {
        allotments: function () {
            this.allotmentId = localStorage.getItem('allotmentId');
            this.showAllotmentSelect = this.hastwoAllotments && (localStorage.getItem('allotmentId') ? false : true);
            if (!this.allotmentId) {
                this.allotmentId = this.allotments[0].id
            }
        },
        allotmentId: function () {
            if (this.allotmentId) {
                this.indexReadjustment();
            }
        }
    },
    methods: {
        changePaginate() {
            this.indexReadjustment();
        },
        async listPropertyesType() {
            await propertyTypes.list().then(resp => this.propertiesType = resp.data);
        },
        async listPropertiesStatus() {
            await Properties.listStatusComplet().then(resp => {
                this.propertiesStatus = resp.data;
                this.propertiesStatus.forEach(i => i.text = i.name);
            });
        },
        selectModal(type, id = false) {
            switch (type) {
                case 'create':
                    this.modalTitle = 'LOTS.VALUE_READJUSTMENT';
                    this.modalswitch = '1';
                    break;
                case 'view':
                    this.showReadjustment(id);
                    this.modalswitch = '2';
                    this.modalTitle = 'READJUSTMENT.VIEW_READJUSTMENT';
                    break;
                case 'undoLot':
                    this.showReadjustment(id);
                    this.modalswitch = '3';
                    this.modalTitle = 'READJUSTMENT.UNDO_BY_LOT';
                    break;
            }
        },
        widthMd(size = 1025, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },
        targetConversion(tag) {
            if (tag === 'allotment') return 'Loteamento';
            if (tag === 'properties') return 'Lotes'
            if (tag === 'propertiesType') return 'Tipo de Lote'
            return 'Quadra'
        },
        showReadjustment(id) {
            this.$store.commit('changeLoading', true);
            Readjustment.show(id).then(async (resp) => {
                this.readjust = resp.data;
                if (this.readjust.target === 'allotment') {
                    await Allotments.show(this.readjust.allotment_id).then((resp) => this.readjust.allotment = resp.data);
                }
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        typeConversion(type) {
            if (type === 0) return 'Reais';
            if (type === 1) return 'Porcentagem'
            return 'Valor por metro'
        },
        toCurrency(value) {
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
        dateFormat(value) {
            let dateAndTime = value.split('T');
            let date = dateAndTime[0].split('-');
            let time = dateAndTime[1].split('.');
            if (!this.widthMd(768)) {
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}`;
            } else return `${date[2]}/${date[1]}/${date[0]}`;

        },
        allotmentSelected() {
            this.allotmentId = localStorage.getItem('allotmentId');
        },
        async indexReadjustment(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);
            if (!this.allotmentId && this.allotments) {
                this.allotmentId = this.allotments[0].id
            }
            if (this.allotmentLoad || this.allotmentId) {
                Readjustment.index(this.allotmentId, filter, page, this.perPage).then((resp) => {
                    this.items = resp.data;
                    this.$store.commit('changeLoading', false);
                }).catch(() => {
                    this.$store.commit('changeLoading', false);
                });
            }
        },
    }
}
</script>

<style scoped>

</style>