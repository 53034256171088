<template>
   <div class="modal-row2 modal-height-max" v-if="iten">
      <div class="modal-body modal-row2 card pb-5" style="border-radius: 0px">
         <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
               <div class="form-group">
                  <label for="type">{{ t('READJUSTMENT.TYPE') }}: </label>
                  <input type="text" id="type" name="type"
                         :value="iten.type == 0 ? 'Reais' : iten.type == 1 ? 'Porcentagem' : 'Valor por Metro'"
                         class="form-control" disabled>
               </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
               <div class="form-group">
                  <label for="priceAfter">{{ t('READJUSTMENT.PRICE_AFTER') }}: </label>
                  <input type="text" id="priceAfter" name="priceAfter"
                         :value="iten.type === 1 ? (toCurrency(iten.value).replace('R$', '')).replace(',', '.') + ' %': toCurrency(iten.value)"
                         class="form-control" disabled>
               </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
               <div class="form-group">
                  <label for="created">{{ t('READJUSTMENT.CREATED_AT') }}: </label>
                  <input type="text" id="created" name="created" :value="dateFormat(iten.created_at)"
                         class="form-control" disabled>
               </div>
            </div>
            <label class="ml-3">{{ t('READJUSTMENT.APPLY_IN') }}: </label>
            <div v-if="iten.target === 'allotment' && iten.allotment"
                 class="accordion mt-1 toggle-icons col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                 id="toggleIconsAllotment">
               <div class="accordion-container">
                  <div class="accordion-header" id="toggleIconsAllotments">
                     <a data-toggle="collapse" :data-target="'#toggleIconsCollapseM' + iten.allotment.id"
                        :id="`openBlock${iten.allotment.id}`"
                        :aria-controls="'toggleIconsCollapse' + iten.allotment.id"
                        style="cursor: default">
                        {{ iten.allotment.name }}
                     </a>
                  </div>
               </div>
            </div>

            <div v-if="this.iten.target === 'blocks' && blocks"
                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
               <div v-for="(block, key) in blocks" :key="key">
                  <div class="accordion toggle-icons" :id="`toggleIconsBlock${block.block}`">
                     <div class="accordion-container">
                        <div class="accordion-header" :id="`toggleIconsBlocks${block.block}`">
                           <a data-toggle="collapse" :data-target="`#toggleIconsCollapseAllotment${block.block}`"
                              :id="`openBlock${block.block}`" style="cursor: default"
                              class="col-6"
                              aria-controls="toggleIconsCollapseAllotment">
                              {{ t('PROPERTIES.BLOCK') }} {{ block.block }}
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div v-if="this.iten.target === 'propertiesType' && types"
                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
               <div v-for="(type, key) in types" :key="key">
                  <div class="accordion toggle-icons" :id="`toggleIconsBlock${type}`">
                     <div class="accordion-container">
                        <div class="accordion-header" :id="`toggleIconsBlocks${type}`">
                           <a data-toggle="collapse" :data-target="`#toggleIconsCollapseAllotment${type}`"
                              :id="`openBlock${type.name}`" style="cursor: default"
                              class="col-6"
                              aria-controls="toggleIconsCollapseAllotment">
                             Lotes {{ type }}
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div v-if="this.iten.target === 'properties' && blocks"
                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
               <div v-for="(block, key) in blocks" :key="block">
                  <div class="accordion toggle-icons" id="toggleIcons">
                     <div class="accordion-container">
                        <div class="accordion-header" :id="'toggleIcons' + block.block">
                           <a data-toggle="collapse" :data-target="'#toggleIconsCollapseM' + block.block"
                              :id="`openBlock${block.block}`"
                              :aria-expanded="blocks[key].open ? true :  false"
                              :aria-controls="'toggleIconsCollapseM' + block.block"
                              @click="blocks[key].open = !blocks[key].open">
                              {{ t('PROPERTIES.BLOCK') }} {{ block.block }}
                           </a>
                        </div>
                        <div :id="'toggleIconsCollapseM' + block.block" class="collapse"
                             :aria-labelledby="'toggleIcons' + block.block" :data-parent="'#toggleIcons' + block.block">
                           <div class="accordion-body">
                              <div class="row gutters">
                                 <div v-for="lot in block.lots" :key="lot.id"
                                      class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 text-center position-relative">
                                    <div class="d-flex h-100">
                                       <div class="card w-100" :id="lot.id"
                                            :style="[`background-color: ${lot.property.properties_status?.backgroud_collor}`]">
                                          <h3 class="mt-1"
                                              :style="{'color': lot.property.properties_status.text_collor}">{{
                                                lot.property.name
                                             }}</h3>
                                          <p class="hide-text" :style="{'color': lot.property.properties_status.text_collor}">
                                             {{ lot.property.properties_types.name }}</p>
                                          <p :style="{'color': lot.property.properties_status.text_collor}">
                                             {{ lot.property.total_area }}
                                             m²</p>
                                          <p class="font-weight-bold"
                                             :style="{'color': lot.property.properties_status.text_collor}">
                                              {{
                                                  lot?.properties_status?.type === 1 ? (toCurrency(lot.property.price).replace('R$', '%')).replace(',', '.') : toCurrency(lot.property.price)
                                              }}</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer justify-content-end" style="margin-top: -1.14rem;">
         <div>
            <button class="btn btn-primary" data-dismiss="modal">{{ t('ACTIONS.CLOSE') }}
            </button>
         </div>
      </div>
   </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

export default {
   name: "showReadjustmentComponent",
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   props: {
      reajustIndex: {
         type: Object,
         required: true,
      }
   },
   data() {
      return {
         iten: null,
         blocks: [],
         types: [],
         valueConfigPercentage: {
            type: 1,
            max: 100000000000,
            prefix: '',
            decimal: ',',
            thousands: '.',
            suffix: ' %',
            disabled: false,
            minimumNumberOfCharacters: 0,
         },
         valueConfigFixed: {
            type: 0,
            suffix: '',
            decimal: ',',
            thousands: '.',
            prefix: 'R$ ',
            disabled: false,
            max: 100000000000,
            minimumNumberOfCharacters: 1,
         }
      }
   },
   mounted() {
      this.iten = this.reajustIndex;
      this.getBlocks();
      this.getLots();
      this.getTypes();
   },
   watch: {
      reajustIndex: function () {
         this.iten = this.reajustIndex;
         this.blocks = [];
         this.getBlocks();
         this.getLots();
         this.getTypes();
      },
   },
   methods: {
      dateFormat(value) {
         let dateAndTime = value.split('T');
         let date = dateAndTime[0].split('-');
         let time = dateAndTime[1].split('.');
         return `${date[2]}/${date[1]}/${date[0]} ás ${time[0]}`;
      },
      toCurrency(value) {
         return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
      },
      widthMd(size = 1025, smaller = '<') {
         if (smaller == '<') {
            return window.innerWidth < size;
         } else {
            return window.innerWidth > size;
         }
      },
      getBlocks() {
         if (this.iten.target !== 'allotment') {
            this.iten.properties_readjusted.forEach((item, key) => {
               if (key === 0) this.blocks.push(item.property);
               let has = false
               this.blocks.forEach(block => {
                  if (item.property.block === block.block) has = true
               })
               if (!has) this.blocks.push(item.property);
            })
         }
      },
      getTypes(){
         if (this.iten.target === 'propertiesType') {
            this.iten.properties_readjusted.map((item) => {
               if (!this.types.includes(item.property.properties_types.name)){
                  this.types.push(item.property.properties_types.name)
               }
            })
         }
      },
      getLots() {
         if (this.iten.target === 'properties') {
            let aux = this.blocks;
            this.blocks.forEach((item, key) => {
               aux[key].lots = [];
               this.iten.properties_readjusted.forEach(rejusted => {
                  if (item.block === rejusted.property.block) aux[key].lots.push(rejusted);
               });
            });
            this.blocks = aux;
         }
      }
   }
}
</script>

<style scoped>

</style>